import React, { Fragment, useEffect, useState } from 'react';
import { Label, Checkbox } from '@rebass/forms';
import { Flex, Text, Box, Button, Image } from 'rebass/styled-components';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import Dropdown from '../components/Dropdown';
import StyledRectangle from '../components/StyledRectangle';
import { getReportService } from '../services/services';
import Question from '../components/Question';
import Answer from '../components/Answer';
import FadeLoader from 'react-spinners/FadeLoader';
import CardTitle from '../components/CardTitle';
import CardSubtitle from '../components/CardSubtitle';
import CardText from '../components/CardText';
import AnalysisResults from '../sections/AnalysisResults';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import MessageSent from '../components/MessageSent';
import { shareReportService } from '../services/services';
import { STORAGE_KEYS } from '../resources/constants';
import InputBox from '../components/InputBox';

import circuitRight from '../../assets/images/circuit1@1.5x.svg';
import animationCircuit from '../../assets/images/animation_circuit.gif';
import SecondarySection from '../components/SecondarySection';

const StyledDate = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #8b97a2;
`;

const StyledBox = styled(Box)`
  @keyframes borderColorChange {
    0% {
      border-color: rgba(10, 153, 199, 0);
    }
    50% {
      border-color: rgba(10, 153, 199, 1);
    }
    100% {
      border-color: rgba(10, 153, 199, 0);
    }
  }
`;

const StyledLabel = styled(Label)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8b97a2;
`;

const Report = () => {
  const [results, setResults] = useState(null);
  const [email, setEmail] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [sendingReport, setSendingReport] = useState(false);
  const [quizIdExists, setQuizIdExists] = useState(false);
  const [subscribe, setSubscribe] = useState(true);

  const data_content = useStaticQuery(
    graphql`
      query MyQueryReport {
        contentfulReport {
          title
          subscribeNewsletter
          packageSection {
            ... on ContentfulSecondarySection {
              contentful_id
              internal {
                type
              }
              title
              text {
                text
              }
              #              icon {
              #                contentful_id
              #                file {
              #                  fileName
              #                  url
              #                }
              #              }
              #              button {
              #                contentful_id
              #                text
              #                url
              #                urlInternal
              #              }
            }
          }
        }
      }
    `
  );

  const getReport = () => {
    getReportService()
      .then(response => {
        if (response.status == '200') {
          setResults(response.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.QUIZ_ID)) {
      navigate('/');
    } else {
      setQuizIdExists(true);
      getReport();
    }
  }, []);

  const handleTrackCustomEvent = actionName => {
    trackCustomEvent({
      category: 'report',
      action: actionName
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setSendingReport(true);

    handleTrackCustomEvent('share report');

    const data = {
      email: email,
      addToNewsletter: subscribe
    };

    shareReportService(data)
      .then(response => {
        if (response.status == '200') {
          setMessageSent(true);
          setSendingReport(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSubscribe = () => {
    setSubscribe(subscribe => !subscribe);
  };

  if (!quizIdExists) return null;
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      pb={5}
      maxWidth="550px"
      width="100%"
    >
      <StyledRectangle bg="white" py={5}>
        <Box as="form" id="ReportForm" onSubmit={handleSubmit}>
          <Flex justifyContent="space-between" pl={5}>
            <Box width={1} pt={2}>
              <CardTitle>{results && results.title}</CardTitle>
              <StyledDate pt={4}>
                {' '}
                Date:{' '}
                {new Date().toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </StyledDate>
            </Box>
            <Image src={circuitRight} alt="circuit-right" heigth="75px" />
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            {!messageSent && (
              <Fragment>
                {sendingReport && (
                  <Flex
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Image src={animationCircuit} alt="animation-circuit" />
                  </Flex>
                )}
                {!sendingReport && (
                  <Fragment>
                    <Flex flexWrap="wrap" px={5} my={4} width={1}>
                      <InputBox
                        label="Email Address"
                        placeholder="Email"
                        value={email}
                        setValue={setEmail}
                        required="required"
                        type="email"
                      />
                    </Flex>
                    <Flex justifyContent="center" alignItems="center" px={6}>
                      <Label>
                        <Box width={[1 / 8, 1 / 12]}>
                          <Checkbox
                            id="suscribeNewsletter"
                            name="suscribeNewsletter"
                            onClick={handleSubscribe}
                            color="#d04c50"
                            defaultChecked={true}
                          />
                        </Box>
                        <Box width={[7 / 8, 11 / 12]}>
                          <StyledDate>
                            {data_content.contentfulReport.subscribeNewsletter}
                          </StyledDate>
                        </Box>
                      </Label>
                    </Flex>
                    <Button
                      variant="bDarkRed"
                      height="36px"
                      width="147px"
                      my={4}
                      type="submit"
                    >
                      Share Report
                    </Button>
                  </Fragment>
                )}
              </Fragment>
            )}

            {messageSent && <MessageSent />}
          </Flex>
        </Box>
      </StyledRectangle>

      {results && (
        <AnalysisResults
          buttonReport={false}
          dc={data_content}
          results={results}
          hd={handleTrackCustomEvent}
        />
      )}

      {!results && (
        <Flex
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          my={5}
        >
          <FadeLoader
            height={15}
            width={5}
            radius={2}
            color={'#ff5e63'}
            loading="true"
          />
        </Flex>
      )}

      <Fragment>
        {results &&
          results.sections.map((section, index) => (
            <Fragment key={index}>
              <StyledRectangle bg="white" py={5} my={5}>
                <Box px={5}>
                  <CardSubtitle color="#2f261e">{section.title}</CardSubtitle>
                  <CardText my={2}>{section.subTitle}</CardText>
                </Box>
                <Box px={3}>
                  {section.questions.map((question, index) => (
                    <Fragment key={question.questionEntryId}>
                      <Question
                        number={index + 1}
                        question={question.question}
                      />
                      {question.response != 'No response' && (
                        <Answer
                          answer={question.response}
                          icon={question.responseIcon}
                        />
                      )}
                      <Flex flexDirection="column" px={[3, 10]}>
                        {question.subQuestions &&
                          question.subQuestions.length == 1 && (
                            <Fragment>
                              <Question
                                number={index + 1 + 'a'}
                                question={question.subQuestions[0].subQuestion}
                              />
                              <Answer
                                answer={question.subQuestions[0].response}
                                icon={question.responseIcon}
                              />
                            </Fragment>
                          )}

                        {question.subQuestions &&
                          question.subQuestions.length > 1 &&
                          question.subQuestions.map((subQuestion, indexSQ) => (
                            <Flex flexWrap="no-wrap" key={indexSQ}>
                              <Answer answer={subQuestion.subQuestion} />
                              <Answer
                                answer={subQuestion.response}
                                icon={null}
                              />
                            </Flex>
                          ))}
                      </Flex>
                      {index != section.questions.length - 1 && (
                        <Box height="1px" bg="#dbe2e7" my={2}></Box>
                      )}
                    </Fragment>
                  ))}
                </Box>
              </StyledRectangle>
              <StyledRectangle bg="white">
                <Dropdown
                  question="What does this mean?"
                  answer={section.means}
                  fontSize={4}
                />
              </StyledRectangle>
            </Fragment>
          ))}
      </Fragment>
      {results && data_content && (
        <StyledRectangle my={4}>
          {data_content &&
            data_content.contentfulReport &&
            data_content.contentfulReport.packageSection.map(section => (
              <Fragment key={section.contentful_id}>
                {section.internal.type === 'ContentfulSecondarySection' && (
                  <StyledBox
                    style={{
                      border: '3px solid #0A99C7',
                      animation: 'borderColorChange 3s infinite'
                    }}
                  >
                    <SecondarySection
                      data_section={section}
                      handleTrackCustomEvent={handleTrackCustomEvent}
                      my={15}
                    />
                  </StyledBox>
                )}
              </Fragment>
            ))}
        </StyledRectangle>
      )}
    </Flex>
  );
};

export default Report;
