import React from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

const NumberText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #8b97a2;
`;

const QuestionText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #31373b;
`;

const Question = ({ number, question }) => {
  return (
    <Box width={1} py={2} px={2}>
      <NumberText py={2}>Question #{number}</NumberText>
      <QuestionText>{question}</QuestionText>
    </Box>
  );
};

export default Question;
