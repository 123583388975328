import React, { useEffect, useState, Fragment } from 'react';
import { Flex, Text, Box, Button, Image } from 'rebass/styled-components';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import ProgressBar from '../components/ProgressBar';
import StyledRectangle from '../components/StyledRectangle';
import { getQuizAnalysisService } from '../services/services';
import CardTitle from '../components/CardTitle';
import CardText from '../components/CardText';

import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import animationCircuit from '../../assets/images/animation_circuit.gif';
import SecondarySection from '../components/SecondarySection';

const StyledProgressBarTitle = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: #161f26;
`;

const StyledProgressBarScore = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  text-align: right;
  color: #8b97a2;
`;

const StyledSubText = styled(Text)`
  font-size: 10px;
  font-weight: 900;
  line-height: 1.3;
  letter-spacing: 0.28px;
  color: #000000;
`;

const AnalysisResults = ({ buttonReport, dc, hd, results }) => {
  const [diseasesResults, setDiseasesResults] = useState(null);
  const [lupusData, setLupusData] = useState(null);

  let ethnicity = null;
  if (results && results.sections) {
    const sections = Object.values(results.sections);
    sections.forEach(section => {
      const qn = section.questions;
      if (qn) {
        const ethnicityQuestion = qn.find(question =>
          question.question.toLowerCase().includes('ethnicity')
        );
        if (ethnicityQuestion) {
          ethnicity = ethnicityQuestion.response;
        }
      }
    });
  }

  // const ethnicityList = ['African American or Black', 'Caucasian or White'];
  // const isProactiveEligible = ethnicityList.includes(ethnicity);
  // const isProactiveEligible = ethnicity !== ethnicityList[0];
  // const isProactiveEligible = false;

  const data_content = useStaticQuery(
    graphql`
      query MyQueryAnalysisResults {
        contentfulAnalysis {
          title
          text {
            text
          }
          button {
            text
            url
            urlInternal
          }
          subText
          subIcon {
            file {
              fileName
              url
            }
          }
        }
      }
    `
  );

  const getQuizAnalysis = () => {
    getQuizAnalysisService()
      .then(response => {
        if (response.status == '200') {
          setDiseasesResults(response.data.diseasesResults);

          // console.log(response.data.diseasesResults)
          setLupusData(
            response.data.diseasesResults.find(disease => {
              return disease.diseaseName === 'Lupus (SLE)';
            })
          );
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    let delay = 0;
    if (buttonReport) delay = 3000;

    setTimeout(function() {
      getQuizAnalysis();
    }, delay);
  }, []);

  const handleViewReport = () => {
    trackCustomEvent({
      category: 'analysis',
      action: 'next steps'
    });
  };

  return (
    <Flex
      flexDirection="column"
      maxWidth="550px"
      justifyContent="center"
      alignContent="center"
      pt={5}
    >
      <StyledRectangle bg="white" px={5} py={5}>
        <CardTitle>{data_content.contentfulAnalysis.title}</CardTitle>
        <CardText my={2}>{data_content.contentfulAnalysis.text.text}</CardText>
        {!diseasesResults && (
          <Flex
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Image src={animationCircuit} alt="animation-circuit" />
          </Flex>
        )}
        {diseasesResults &&
          diseasesResults.map((disease, index) => (
            <Fragment key={index}>
              <Box mt={4}>
                <Flex flexWrap="wrap" justifyContent="space-between" my={1}>
                  <StyledProgressBarTitle>
                    {disease.diseaseName}
                  </StyledProgressBarTitle>
                  <StyledProgressBarScore>
                    {disease.resultLegend}
                  </StyledProgressBarScore>
                </Flex>
                <ProgressBar
                  value={
                    disease.resultPercentage < 1
                      ? '1'
                      : disease.resultPercentage
                  }
                  bgcolor={
                    disease.resultPercentage < 50 ? '#ff5e63' : '#d04c50'
                  }
                />
              </Box>
            </Fragment>
          ))}

        {diseasesResults && (
          <Fragment>
            {buttonReport && (
              <Fragment>
                <Flex justifyContent="center" alignItems="center" mt={7}>
                  <GatsbyLink
                    to={
                      data_content.contentfulAnalysis.button.urlInternal
                        ? data_content.contentfulAnalysis.button.urlInternal
                        : data_content.contentfulAnalysis.button.url
                    }
                    target={
                      data_content.contentfulAnalysis.button.urlInternal
                        ? ''
                        : '_blank'
                    }
                  >
                    <Button variant="bRed" onClick={() => handleViewReport()}>
                      {data_content.contentfulAnalysis.button.text}
                    </Button>
                  </GatsbyLink>
                </Flex>
                <Flex mt={6} alignItems="center">
                  <Image
                    mr={2}
                    src={data_content.contentfulAnalysis.subIcon.file.url}
                    alt={data_content.contentfulAnalysis.subIcon.file.fileName}
                    width="50px"
                  ></Image>
                  <StyledSubText>
                    {data_content.contentfulAnalysis.subText}
                  </StyledSubText>
                </Flex>
              </Fragment>
            )}
          </Fragment>
        )}
      </StyledRectangle>
    </Flex>
  );
};

export default AnalysisResults;
