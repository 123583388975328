import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

const StyledProgress = styled.div`
   {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #f0f5f6;
    border-radius: 40px;
  }
`;

const StyledProgressBar = styled(Box)`
display: flex;
flex-direction: column;
justify-content: center;
overflow: hidden;
color: #fff;
text-align: center;
white-space: nowrap;
transition: width 0.6s ease;
}`;

const ProgressBar = ({ bgcolor, value }) => {
  return (
    <StyledProgress>
      <StyledProgressBar
        width={value + '%'}
        backgroundColor={bgcolor}
      ></StyledProgressBar>
    </StyledProgress>
  );
};

export default ProgressBar;
