import React from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';

const MessageText = styled(Text)`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
  text-align: center;
  color: #ff5e63;
`;

const Message = ({ text }) => {
  return <MessageText>{text}</MessageText>;
};

export default Message;
