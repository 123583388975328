import React from 'react';
import Layout from '../components/Layout';
import Report from '../sections/Report';

const ReportPage = () => (
  <Layout logoHeader={true} mainHeader={false}>
    <Report />
  </Layout>
);

export default ReportPage;
