import React, { useState } from 'react';
import { Box } from 'rebass/styled-components';
import { Label, Input } from '@rebass/forms';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  border-radius: 4px;
  border: solid 1px #f0f5f6;
  background-color: #ffffff;
  height: 60px;
`;

const StyledLabel = styled(Label)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: #8b97a2;
`;

const StyledInput = styled(Input)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: #161f26;
  ::placeholder {
    font-weight: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #8b97a2;
  }
`;

const InputBox = ({
  label,
  placeholder,
  value,
  setValue,
  required,
  type,
  filter
}) => {
  const handleValueChange = e => {
    let value = e.target.value;

    if (filter == 'onlyLetters') value = value.replace(/[^ñA-Za-z ]/gi, '');

    setValue(value);
  };

  return (
    <StyledBox width={1} px={5} py={value ? 3 : 4}>
      {value && <StyledLabel>{label}</StyledLabel>}
      <StyledInput
        id={value}
        name={value}
        type={type}
        onChange={handleValueChange}
        placeholder={placeholder}
        value={value}
        required={required}
        sx={{
          border: `0`,
          outline: `none`,
          paddingY: `0`,
          paddingX: `0`
        }}
      />
    </StyledBox>
  );
};

export default InputBox;
