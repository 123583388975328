import React from 'react';
import { Box, Text, Image, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

const AnswerText = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #161f26;
`;

const Answer = ({ answer, icon }) => {
  return (
    <Flex flexWrap="no-wrap" width={1} py={2} px={2} alignItems="center">
      {icon && <Image src={icon} alt="icon" width="24px" mr={3}></Image>}
      <AnswerText>{answer}</AnswerText>
    </Flex>
  );
};

export default Answer;
