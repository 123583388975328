import React from 'react';
import { Flex, Image } from 'rebass/styled-components';
import Message from '../components/Message';

import planeRed from '../../assets/images/plane-red.svg';

const MessageSent = () => {
  return (
    <Flex flexDirection="column" width={1} py={5} alignItems="center">
      <Image src={planeRed} alt="plane-red" width={'44px'}></Image>
      <Message text="Message Sent" />
    </Flex>
  );
};

export default MessageSent;
